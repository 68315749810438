@import "styles/main/colors.scss";
@import "styles/main/mixins.scss";

.header {
	background-color: #fff;
	color: $dark;
	padding-right: 80px;
	border-bottom: 1px solid $green;

	@media (max-width: 1720px ) {
		padding-right: 30px;
	}

	@media (max-width: 1500px ) {
		padding-right: 15px;
	}

	.theme-controls {
		margin-left: auto;
	}
}

.header__logo {
	@include sidebar_width;

	svg {
		max-width: 51%;
	}
}

.header__title {
	font-size: 1.71em;
	font-weight: bold;
	
	@media (max-width: 1720px ) {
		font-size: 1.5em;
	}

	@media (max-width: 1500px ) {
		font-size: 1.35em;
	}
}