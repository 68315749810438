@mixin sidebar_width {

	flex: 0 0 350px;

	@media ( max-width: 1720px ) {
		flex: 0 0 300px;
	}

	@media ( max-width: 1500px ) {
		flex: 0 0 240px;
	}
}


@mixin medium_font_size {
	font-size: 1.28em;

	@media (max-width: 1720px) {
		font-size: 1.14em;
	}
	
	@media (max-width: 1500px) {
		font-size: 1em;
	}
}

@mixin smaller_font_size {
	font-size: 1.14em;

	@media (max-width: 1720px) {
		font-size: 1em;
	}

	@media (max-width: 1500px) {
		font-size: 0.93em;
	}
}