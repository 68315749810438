$yellow: #ffd401;
$yellow_transparent: rgba(255, 212, 1, .3);
$dark: #181818;
$dark_light: #262525;

body.contrast {

	background: $dark;


	body,
	.table__th,
	.modal__header .heading,
	.footer__links a::after,
	.file-picker__area-text span,
	.mobile-page-title,
	.issue-list-item div span,
	.issue-content > div span,
	.form-textarea__max-symbols,
	a {
		color: $yellow;
	}

	.page.content-page .content *,
	.page.content-page .content a *,
	.checkbox__text a,
	.checkbox__text a * {
		color: $yellow !important;
	}

	.checkbox__text * {
		color: $yellow !important;
	}

	.menu .to-add-page {
		color: #000;
	}

	.checkbox__text a {
		text-decoration: underline;
	}

	.table__th,
	.pagination__per-page select,
	.marker-cluster,
	.captcha input,
	.picture-picker__preview span,
	.map-controls__btn,
	.issue-list-item,
	.leaflet-bar a, .leaflet-bar a:hover,
	.contact-blocks,
	.modal__header,
	.issue-popup__head,
	.sidebar,
	.footer,
	.map-legend,
	.menu .menu__icon,
	.mobile-header,
	.header {
		background-color: $dark;
		color: $yellow;
	}


	.map-legend__toggle,
	.marker-cluster {
		border-color: $yellow;
	}

	.menu-burger span,
	.pagination__button.active,
	.menu .to-add-page {
		background-color: $yellow;
	}

	.issue-popup,
	.modal__body,
	.mobile-sidebar,
	.app-totals__item,
	.bottom-navigation,
	main {
		background-color: $dark_light;
		color: $yellow;
	}


	.form-input__btn,
	.button {
		background-color: $yellow;
		border-color: $yellow;
		color: #000;

		svg.fill path { fill: #000;}
	}

	.button.transparent {
		background-color: transparent;
		color: $yellow;
	}

	.checkbox__box,
	.form-textarea textarea,
	.form-options__item,
	.form-input input {
		background-color: transparent;
		border-color: $yellow;
		color: $yellow;

		&::placeholder {
			color: $yellow_transparent;
		}
	}

	.banner,
	.pagination__button,
	.step.active,
	.form-options__item.active {
		background-color: $yellow_transparent;
		color: $yellow;
	}

	.table__td {
		background-color: transparent;
	}


	.issue-list-item.open,
	.file-picker__area {
		border-color: $yellow_transparent;
		background-color: rgba(255, 212, 1, .05);
	}


	.pagination__button,
	.add-issue-page__bottom,
	.app-totals__item,
	.desktop-layout .footer,
	.leaflet-bar a, .leaflet-bar a:hover,
	.page-header,
	.table__td { 
		border-color: $yellow_transparent;
	}

	.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
		border: none;
	}

	.table__tr {
		border-right: 1px solid;
		border-left: 1px solid;
		border-color: $yellow_transparent;	
	}

	.table__empty {
		background-color: rgba(255, 255, 255, .1);
	}


	svg.fill path { fill: $yellow; }

	svg.stroke path { stroke: $yellow; }

	svg.close circle { fill: $yellow; }

	.pagination__button svg path { fill: #000;}

	svg.logo {
		* {
			fill: $yellow;
		}
	}

	.has-scrollbar {
		scrollbar-color: $yellow_transparent $dark_light;
		
		&::-webkit-scrollbar-track {
			background: $dark_light;
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: $yellow_transparent;
		}
	}

	@media (max-width: 920px) {
		.filters-wrap {
			background-color: $dark;
		}
	}


	@media (max-width: 767px) {
	
		.steps::before {
			background-color: $yellow;
		}

		.step__number {
			background-color: $dark;
			border-color: $yellow;
		}

		.step.active { 
			.step__number {
				background-color: $yellow;
				color: #000;
			}
		}

		.step.active {
			background: transparent;
		}
	}
}