
$dark: #181818;
$blue: #2B68AE;
// $green: #73BE46;
$green: #4e8430;

$pink: #FF8686;
$dark_gray: #494949;
$dark_light: #262525;
$light_gray: #F6F6F6;
$dim_gray: #666666;
$gray: #d9d9d9;
$red: #DD4040;
$gainsboro: #DBDBDB;
$whisper: #E6E6E6;

$yellow: #ffd401;
$yellow_transparent: rgba(255, 212, 1, 0.3);

@function get_green_rgba ($o) {
	@return "rgba( 115, 190, 70, #{$o})";
}

@function get_blue_rgba ($o) {
	@return "rgba( 43, 104, 174, #{$o})";
}