@import "styles/main/mixins.scss";
@import "styles/main/colors.scss";

.footer {
	background-color: #fff;
	border-top: 1px solid $green;

	@media (max-width: 1590px) {
		font-size: 0.85em;
	}

	@media ( max-width: 1023px ) {
		font-size: 1em;
	}
}

.footer-container {
	max-width: 1920px;
	padding: 15px 15px 10px 365px;

	@media (max-width: 1720px) {
		padding-left: 315px;
	}

	@media (max-width: 1500px) {
		padding-left: 255px;
	}
	
	@media ( max-width: 1315px ) {
		padding-left: 15px;
	}
}

.footer__content {
	margin-bottom: 10px;

	p {
		margin: 0;
		padding-left: 15px;
	}
}

.footer__links {

	a {
		color: $green;
		margin: 0;
		padding: 0;

		&:hover {
			text-decoration: underline;
		}

		&:last-child::after {
			display: none;
		}

		&::after {
			content: "|";
			width: 1px;
			color: #000;
			margin: 0 8px;
			@media (max-width: 1400px) {
				margin: 0 3px;
			}
		}
	}
}

.footer__logos {
	min-width: 40%;

	padding-right: 10px;
	border-right: 1px solid #000;

	span {
		margin-left: 30px;

		@media ( max-width: 1700px ) {
			margin-left: 15px;
		}
	}
}