@import "styles/main/mixins.scss";

.sidebar {
	@include sidebar_width;
	display: flex;
	flex-direction: column;

	.app-totals {
		margin-top: auto;
		padding: 24px 24px 12px;
	}
}


@media ( max-width: 1720px ) {
	.sidebar {
		.app-totals { 
			padding-left: 12px;
			padding-right: 12px;
		}	
		.menu {
			font-size: 1.14em;
		}
	}

	.app-totals__item strong {
		font-size: 1.28em;
	}
}


@media ( max-width: 1500px ) { 
	.sidebar {

		.app-totals__item span {
			font-size: 0.85em;
		}

		.menu {
			font-size: 1.07em;
		}
	}
}

@media ( max-width: 1280px ) {
	.sidebar .menu {
		font-size: 1em;
	}
}
