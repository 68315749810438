.page-header__title {
	font-weight: bold;
	font-size: 1.5em;
	margin-bottom: 8px;
}

.heading {
	font-weight: bold;
	font-size: 1.28em;
	margin-bottom: 20px;
}

@media (max-width: 1720px) {
	.page-header__title {
		font-size: 1.35em;
	}

	.heading {
		font-size: 1.21em;
	}
}

@media (max-width: 1500px) {
	.heading {
		font-size: 1.14em;
	}
}

@media (max-width: 1280px) {
	.page-header__title {
		font-size: 1.28em;
	}
}