@import "styles/main/colors.scss";

.app-totals__item {
	margin-bottom: 11px;
	background-color: $light_gray;
	padding: 10px;
	text-align: center;
	text-transform: uppercase;

	display: flex;
	min-height: 68px;

	line-height: 1;

	strong,
	> div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	strong {
		font-weight: bold;
		font-size: 2em;
		min-width: 35%;
		border-right: 1.5px solid #000;
		padding-right: 10px;
	}

	span {
		font-size: 1em;
		text-transform: lowercase;
	}

	
	> div {
		padding-left: 10px;
	}
}