@import "styles/main/colors.scss";
@import "styles/main/mixins.scss";

.modal {
	margin: auto;
	min-width: 602px;
	max-width: 1170px;
	background-color: #fff;
}

.modal__header {
	background-color: $dark_gray;
	padding: 16px 20px 16px;

	.heading {
		color: #fff;
		margin-bottom: 0;
		margin-right: 15px;
	}

	.close-button {
		margin-left: auto;
	}
}

.modal__body {
	min-height: 210px;
	padding: 25px 20px;
	justify-content: center;
}

.modal__content {
	@include medium_font_size;
	max-height: 60vh;
}

.modal__buttons {
	margin-top: 40px;

	.button {
		margin: 0 7.5px;
	}
}

@media (max-width: 767px) {
	.modal__content {
		max-height: 75vh;
	}
}

@media (max-width: 650px) {
	.modal {
		min-width: 100%;
	}

	.modal__body {
		padding: 20px 13px;	
	}

	.modal__header {
		padding: 15px 13px;
	}
}

@media (max-width: 500px) {
	.modal__buttons {
		flex-wrap: wrap;

		.button {
			width: 290px;
			margin: 10px 0 0 0;
		}
	}
}