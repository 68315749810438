@import "styles/main/colors.scss";
@import "styles/main/mixins.scss";

.button {
	cursor: pointer;
	display: inline-block;
	min-width: 171px;
	background-color: $green;
	border: 1px solid $green;
	color: #000;
	padding: 14px 15px;
	transition: all .4s;

	@include smaller_font_size;

	&:hover {
		color: #fff;
	}
}

.button.disabled {
	opacity: .5;
	cursor: not-allowed;

	&:hover {
		color: #fff;
	}
}

.button.black-transparent {
	background-color: transparent;
	color: #000;

	&:hover {
		color: #fff;
		background-color: rgba( 0, 0, 0, .65);
	}
}

.button.table-button {
	display: flex;
	background-color: transparent;
	font-weight: bold;
	color: $green;
	border: 0;
	padding: 0;
	margin: 0;
	font-size: 1em;
	min-width: auto;
	width: auto;
	text-align: center;
}

.button.small {
	min-width: 99px;
	font-size: 14px;
	padding: 10px;
}

.button.blue {
	background-color: $blue;
	border-color: $blue;
	color: #fff;
}


body.contrast {
	.button.table-button {
		background-color: transparent;
		color: $yellow;
	}
}