@import "styles/main/colors.scss";

.cookies-info {
	position: fixed;
	z-index: 1100;
	bottom: 0;
	width: 100vw;
	line-height: 1.5;

	background-color: $dark;
	color: #fff;

	@media (max-width: 1200px) {
		font-size: 12px;
	}
}

.cookies-info__container {
	display: flex;
	align-items: center;
	max-width: 1200px;
	padding: 25px 15px;
	margin: auto;

	.close-button {
		min-width: 26px;
		margin-left: 20px;
	}
}

.cookies-info__pic {
	width: 40px;
	min-width: 40px;
    height: 40px;
	margin-right: 20px;
	background: no-repeat -26px -282px;
}