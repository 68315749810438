
@mixin desktop_layout ( $header_h, $footer_h ) {
	.footer { height: $footer_h; }
	.header { height: $header_h; }
	.desktop-layout-main-wrap { 
		height: auto;
		min-height: calc( 100vh - #{ $header_h });
	}

	.sidebar {
		position: sticky;
		top: 0;
		max-height: calc( 100vh - #{ $header_h });
	}
}


.desktop-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	@include desktop_layout( 121px, 100px );

	@media ( max-width: 1720px ) {
		@include desktop_layout( 90px, 99px );
	}

	@media ( max-width: 1500px ) {
		@include desktop_layout( 82px, 90px );
	}
}