@import "./colors.scss";
@import "./variables.scss";
@import "./headings.scss";
@import "./mixins.scss";


* {
	box-sizing: border-box;
}

body {
	font-size: 14px;
	font-family: 'Titillium Web', sans-serif;
	color: #000;
	background: #fff;
	margin: 0;
}

input, select {
	font-family: 'Titillium Web', sans-serif;
}

.page {
	max-width: 1400px;
}

.page.content-page {
	.content { 
		font-size: 1.14em;

		a {
			font-weight: bold;
			text-decoration: underline;
			* {
				font-size: 1em !important;
				color: $green !important;
			}
		}

		* {
			font-family: 'Titillium Web', sans-serif !important;
		}

		@media (max-width: 1720px) {
			font-size: 1em;
		}
	}
}

.error-message {
	color: $red;
	font-weight: bold;
	margin: 6px 0;
	font-size: 14px;
}


.has-scrollbar {
	overflow: auto;
	scrollbar-width: thin;
	scrollbar-color: $green $light_gray;

	&::-webkit-scrollbar {
		width: 6px;
	}
	
	&::-webkit-scrollbar-track {
		background: $light_gray;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $green;
		outline: none;
	}
}



.d-none {
	display: none;
}

a {
	transition: all .4s;
	text-decoration: none;
	color: $green;
	
	&:hover {
		text-decoration: none;
	}

	&.underline-black {
		@include smaller_font_size;

		color: #000;
		text-decoration: underline;
	}
}

p {
	margin-top: 0;
}

.black-overlay {
	position: fixed;
	z-index: 9999;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba( 0,0,0, .55);
    top: 0;
    display: flex;
	padding: 20px;

	@media (max-width: 650px ) {
		padding: 20px 13px;
	}
}

.flex {
	display: flex;
}

.df-column {
	display: flex;
	flex-direction: column;
}

.main-wrap {
	display: flex;
	flex: auto;
}

main {
	background-color: $light_gray;
	flex: auto;
	padding: 30px 70px;

	@media ( max-width: 1720px ) {
		padding: 30px;
	}

	@media ( max-width: 1500px ) {
		padding: 22px 15px;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.df-ac {
	display: flex;
	align-items: center;
}

.df-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.thumbnail {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
}

img, svg {
	max-width: 100%;
	height: auto;
}

.hidden {
	display: none !important;
}

.rocketchat-widget {
	bottom: 0px !important;
}

.grecaptcha-badge {
	bottom: 80px !important; 
}