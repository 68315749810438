@import "styles/main/colors.scss";

body.contrast {
	.cssload-whirlpool,
	.cssload-whirlpool::before,
	.cssload-whirlpool::after{
		border: 1px solid rgba(255, 212, 1, 1);
		border-left-color: rgba(255, 212, 1, .15);
}
}  


.loader{
	min-height: 80px;
	position: relative;
 }

 .cssload-whirlpool,
 .cssload-whirlpool::before,
 .cssload-whirlpool::after {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 1px solid $green;
	border-left-color: rgba(0, 0, 0, .15);
	border-radius: 974px;
	-o-border-radius: 974px;
	-ms-border-radius: 974px;
	-webkit-border-radius: 974px;
	-moz-border-radius: 974px;
 }

 .cssload-whirlpool {
	margin: -24px 0 0 -24px;
	height: 49px;
	width: 49px;
	animation: cssload-rotate 1150ms linear infinite;
	-o-animation: cssload-rotate 1150ms linear infinite;
	-ms-animation: cssload-rotate 1150ms linear infinite;
	-webkit-animation: cssload-rotate 1150ms linear infinite;
	-moz-animation: cssload-rotate 1150ms linear infinite;
 }

 .cssload-whirlpool::before {
	content: "";
	margin: -22px 0 0 -22px;
	height: 43px;
	width: 43px;
	animation: cssload-rotate 1150ms linear infinite;
	-o-animation: cssload-rotate 1150ms linear infinite;
	-ms-animation: cssload-rotate 1150ms linear infinite;
	-webkit-animation: cssload-rotate 1150ms linear infinite;
	-moz-animation: cssload-rotate 1150ms linear infinite;
 }

 .cssload-whirlpool::after {
	content: "";
	margin: -28px 0 0 -28px;
	height: 55px;
	width: 55px;
	animation: cssload-rotate 2300ms linear infinite;
	-o-animation: cssload-rotate 2300ms linear infinite;
	-ms-animation: cssload-rotate 2300ms linear infinite;
	-webkit-animation: cssload-rotate 2300ms linear infinite;
	-moz-animation: cssload-rotate 2300ms linear infinite;
 }

 @keyframes cssload-rotate {
	100% {
	  transform: rotate(360deg);
	}
 }

 @-o-keyframes cssload-rotate {
	100% {
	  -o-transform: rotate(360deg);
	}
 }

 @-ms-keyframes cssload-rotate {
	100% {
	  -ms-transform: rotate(360deg);
	}
 }

 @-webkit-keyframes cssload-rotate {
	100% {
	  -webkit-transform: rotate(360deg);
	}
 }

 @-moz-keyframes cssload-rotate {
	100% {
	  -moz-transform: rotate(360deg);
	}
 }
