@import "styles/main/colors.scss";

.menu {
	display: block;
	font-size: 1.28em;
	padding: 15px 40px 0;

	@media (max-width: 1500px) {
		padding-left: 20px;
		padding-right: 15px;
	}

	a {	
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 17px;
		color: #000;

		&.active {
			color: #000;
			font-weight: bold;
		}
	}

	.menu__icon {
		width: 16px;
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $green -15px -72px no-repeat;
		margin-right: 6px;
	}
}

.menu .to-add-page {
	display: flex;
	padding: 10px 15px;
	max-width: 150px;
	border: none;
	background-color: $green;
	color: #fff;
	font-size: 1em;

	line-height: 1.3;

	&.active { 
		color: #fff;
	}

	span {
		margin-left: 10px;
	}

	svg {
		* {
			fill: #fff;
		}
	}
}

body.contrast {

	.menu a {
		color: $yellow;
	}

	.menu .to-add-page,
	.menu .to-add-page.active {

		a {
			color: #000;
		}

		svg {
			* {
				fill: #000;
			}
		}
	}	
}