@import "styles/main/colors.scss";

.theme-controls {
	
	.font-size-switcher a,
	.contrast-switcher,
	.sign-translator {
		width: 31px;
		min-width: 31px;
		height: 23px;
		background-color: #eee;
		margin-left: 1px;
		svg {
			width: 19px;
			height: auto;
		}
	}

	.font-size-switcher a {
		color: #000;
		font-weight: bold;
		font-size: 14px;
	}

	.bip-link {
		margin-left: 15px;
		width: 42px;
	    height: 23px;
	    background: no-repeat -60px -15px;
	}
} 

body.contrast {
	.theme-controls {
	
		.font-size-switcher a,
		.contrast-switcher,
		.sign-translator {
			background-color: $yellow;
			color: #000;

			svg.fill {
				* {
					fill: #000;
				}
			}
		}
	}
}